<template>
	<div class="main-contents hr agent">
		<div class="tit">매니저 정보</div>
		<div class="search-box">
			<InputComp v-model="input.searchKeyword" placeholder="매니저명" @keyup.enter="getAgentList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getAgentList('1')"/>
		</div>
		<!-- 매니저 정보 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="13%">
					<col width="13%">
					<col width="14%">
					<col width="6%">
					<col width="50%">
					<!-- <col width="19%">
					<col width="19%">
					<col width="19%"> -->
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>매니저</th>
						<th>관리자 번호</th>
						<th>아이디</th>
						<th>성별</th>
						<th>총 프로젝트</th>
						<!-- <th>수행중인 프로젝트</th>
						<th>수행예정 프로젝트</th>
						<th>완료 프로젝트</th> -->
					</tr>
				</thead>
				<tbody>
					<template v-if="agentList.length > 0">
						<tr v-for="(agent, rowIdx) in agentList" :key="rowIdx">
							<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
							<td class="score name" @click="popupOpen(agent.mberSeq)">{{agent.mberNm}} &#9654;</td>
							<td class="score">{{agent.mberSeq}}</td>
							<td class="score">{{agent.loginId}}</td>
							<td class="score">{{agent.gender | gender}}</td>
							<td class="score totalPrj">
								<span class=totalNumber>{{agent.projectIngCnt + agent.projectPreCnt + agent.projectEndCnt + agent.projectProCnt}}</span>
								<div class="totalPrjBtn" @click=goToMangerPrjList(agent.mberNm)>리스트 보기 &#9654;</div>
							</td>
							<!-- <td class="score">{{agent.projectPreCnt}}</td>
							<td class="score">{{agent.projectEndCnt}}</td> -->
						</tr>
					</template>
					<!-- 관련내용 없을 경우 -->
					<template v-else>
						<tr>
							<td colspan="8" class="none">매니저가 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		
		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			input : {
				searchKeyword : ''
			},
			pageInfo : {},
			agentList : []
		}
	},

	components : {
		pagingComp
	},

	mounted() {
		this.getAgentList();
	},

	methods : {
		getAgentList(div) {
			if(div) this.input.pageIndex = 1;
			
			this.$.httpPost('/api/prj/adm/getAgentList', this.input)
				.then(res => {
					//console.log(res.data.list);
					this.agentList = res.data.list;
					this.pageInfo = res.data.pageInfo;
					
					//window.scroll(0, 0);
				}).catch(this.$.httpErrorCommon);
		}, 
		
		// 페이징 이동
		goPage(page) {
			this.input.pageIndex = page;
			this.getAgentList();
		},

		popupOpen(seq) {
			var param = {};
			param.div = 'agt';
			param.reqMberSeq = seq;

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		goToMangerPrjList(mberNm){ 
			this.$router.push({
				name: 'PRJ910M01',
				query:{
					searchGubun:'3',
					searchKeyword:mberNm,
					caller : {
						name: this.$route.name,
						params: this.input
					}

				}
			});
		},
		
	}
}
</script>